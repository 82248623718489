@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
 
  overflow: none;
  overscroll-behavior: none;
}